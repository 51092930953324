import { defineStore } from 'pinia';
import { json } from '@sahnee/ajax';
import { Customer } from './customer';

interface State {
  // admins: User[];
  users: User[];
  loading: number;
  loaded: boolean;
  // loaded: {
  //   admins: boolean;
  //   users: boolean;
  // };
}

/**
 * A user.
 */
export interface User {
  _id: string;
  _rev: string;
  doc_type: 'new_contact'; // TODO: new_contact -> user
  company: string;
  hash: string;
  admin: boolean;
  activated: boolean;
  salutation: string;
  forename: string;
  surname: string;
  language: string;
  phone: string;
  email: string;
  email_notifications: {
    download: EmailNotification;
    video: EmailNotification;
  };
  type: 'new_contact'; // TODO: still necessary?
  // Authenticator
  authenticator: {
    active: boolean,
    backupCodes: number,
    secret: string,
  };
  // Forgot password
  forgotPassword?: {
    request: boolean,
    timestamp: string,
  };
  password: {
    hashed: '',
    salt: '',
    scheme: '',
    timestamp: string,
  }
  // Additional information
  adminCandidate?: boolean;
  companyName?: string;
}

export interface EmailNotification {
  active: boolean;
  language: string;
  products: string[];
}

export const defaultUser: Omit<User, '_id' | '_rev'> = {
  doc_type: 'new_contact',
  company: '',
  hash: '',
  admin: false,
  activated: false,
  salutation: '',
  forename: '',
  surname: '',
  language: '',
  phone: '',
  email: '',
  email_notifications: {
    download: {
      active: false,
      language: 'Deutsch',
      products: [],
    },
    video: {
      active: false,
      language: 'Deutsch',
      products: [],
    },
  },
  type: 'new_contact',
  authenticator: {
    active: false,
    backupCodes: 0,
    secret: ''
  },
  password: {
    hashed: '',
    salt: '',
    scheme: '',
    timestamp: '',
  }
}

function prepare(user: Partial<User>) {
  const fullUser: Partial<User> = { ...defaultUser, ...user };
  const defaultKeys = ['_id', '_rev', ...Object.keys(defaultUser)];
  const additionalKeys = Object.keys(fullUser).filter(key => !defaultKeys.includes(key)) as (keyof User)[];
  for (const key of additionalKeys) {
    delete fullUser[key];
  }
  return fullUser;
}

/**
 * The user store.
 */
export const useUserStore = (preload = false) => {
  // Define a local store reference
  const useStore = defineStore('user', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        users: [],
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if a given email is already saved in another contact document.
       * @param email The email.
       * @param excludedIds The contact IDs to exclude.
       * @returns Whether the given email already exists in another contact or not.
       */
      emailExists: (state: State) => {
        return (email: string, excludedIds: string | string[] = []) => {
          if (!Array.isArray(excludedIds)) {
            excludedIds = [excludedIds];
          }
          return state.users
            .filter(user => !excludedIds.includes(user._id))
            .map(user => user.email.toLowerCase().trim())
            .includes(email.toLowerCase().trim());
        }
      },
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Search for users by their ID.
       * @param ids The ID or an array of IDs of the users.
       * @returns An array of users from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const users = state.users.filter(user => ids.includes(user._id));
          return ids.length === 1 ? users[0] : users;
        }) as ((ids: string | [string]) => User) & ((ids: string[]) => User[])
      },
      /**
       * Search for users by their email.
       * @param ids The email or an array of emailss of the users.
       * @returns An array of users from the store.
       */
      getByEmail: (state: State) => {
        return ((emails: string | string[]) => {
          if (!Array.isArray(emails)) {
            emails = [emails];
          }
          const users = state.users.filter(user => emails.includes(user.email));
          return emails.length === 1 ? users[0] : users;
        }) as ((emails: string | [string]) => User) & ((emails: string[]) => User[])
      },
      getAdmins: (state: State) => {
        return () => {
          return state.users.filter(user => user.admin) as User[]
        }
      },
      getAdminCandidates: (state: State) => {
        return () => {
          return state.users.filter(user => user.adminCandidate) as User[]
        }
      },
      /**
       * Search for user by their associated customer ID.
       * @param ids The ID or an array of IDs of customers which the contacts belong to.
       * @returns An array of users from the store.
       */
      getByCustomerId: (state: State) => {
        return (ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          return state.users.filter(user => ids.includes(user.company));
        }
      },
      getByCustomer() {
        return (customer: Customer) => {
          return this.getByCustomerId(customer._id);
        }
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const users = await json<User[]>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.users = users;
          this.loaded = true;
        } finally {
          this.loading--;
        }
      },
      /**
       * Creates a new user.
       * The user will automatically be saved in the database and added to the store.
       * @param user The user.
       */
      async create(user: Partial<User>, password?: string) {
        this.loading++;
        try {
          const created = await json<User>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'create',
              user: prepare(user),
              password
            }
          });
          if (created) {
            this.users.push(created);
            return {
              success: true,
              data: created,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Reads an existing user.
       * The user will automatically be added to the store.
       * @param id The user ID.
       */
      async readById(id: string) {
        this.loading++;
        try {
          const user =  await json<User>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'read-by-id',
              id
            }
          });
          if (user) {
            if (!this.users.map(user => user._id).includes(user._id)) {
              this.users.push(user);
            }
            return {
              success: true,
              data: user,
            };
          }
        } catch (error: unknown) {
        return {
          success: false,
          error: (error as Error).message,
        }
        } finally {
          this.loading--;
        }
      },
      /**
       * Reads an existing user.
       * The user will automatically be added to the store.
       * @param email The user email.
       */
      async readByEmail(email: string) {
        this.loading++;
        try {
          const user =  await json<User>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'read-by-email',
              email
            }
          });
          if (user) {
            if (!this.users.map(user => user._id).includes(user._id)) {
              this.users.push(user);
            }
            return {
              success: true,
              data: user,
            };
          }
        } catch (error: unknown) {
        return {
          success: false,
          error: (error as Error).message,
        }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting user.
       * The user will automatically be saved in the database and updated in the store.
       * @param user The user.
       */
      async update(user: Partial<User>, password?: string) {
        this.loading++;
        try {
          const updated = await json<User>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'update',
              user: prepare(user),
              password
            }
          });

          if (updated) {
            const index = this.users.findIndex((user: User) => user._id === updated._id);
            this.users[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting user.
       * The user will automatically be saved in the database and updated in the store.
       * @param user The user.
       */
      async updateAuthenticator(active: boolean, email: string, otp: string) {
        this.loading++;
        try {
          const {user: updated, backupCodes} = await json<{user: User; backupCodes: string[]}>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'update-authenticator',
              active,
              email,
              otp,
            }
          });

          if (updated) {
            const index = this.users.findIndex((user: User) => user._id === updated._id);
            this.users[index] = updated;
            return {
              success: true,
              data: updated,
              backupCodes,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting user.
       * The user will automatically be saved in the database and updated in the store.
       * @param user The user.
       */
       async createBackupCodes(email: string) {
        this.loading++;
        try {
          const {user: updated, backupCodes} = await json<{user: User; backupCodes: string[]}>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'create-backup-codes',
              email,
            }
          });

          if (updated) {
            const index = this.users.findIndex((user: User) => user._id === updated._id);
            this.users[index] = updated;
            return {
              success: true,
              data: updated,
              backupCodes,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Delete an exisiting user.
       * The user will automatically be deleted from the database and the store.
       * @param ids The ID or an array of IDs of users to delete.
       */
      async delete(ids: string | string[]) {
        if (!Array.isArray(ids)) {
          ids = [ids];
        }
          this.loading++;
          try {
          const deletedDocs = await json<User[]>('/api/store/users', {
            method: 'DELETE',
            json: {
              action: 'delete',
              ids
            }
          });
          for (const deleted of deletedDocs) {
            const index = this.users.findIndex((user: User) => user._id === deleted._id);
            this.users.splice(index, 1);
          }
          return {
            success: true,
            data: deletedDocs,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Delete an exisiting user.
       * The user will automatically be deleted from the database and the store.
       * @param ids The ID or an array of IDs of users to delete.
       */
       async deleteCurrent(email: string, password: string, otp: string) {
          this.loading++;
          try {
          const deleted = await json<User>('/api/store/users', {
            method: 'DELETE',
            json: {
              action: 'delete-current',
              email,
              password,
              otp,
            }
          });
          const index = this.users.findIndex((user: User) => user._id === deleted._id);
          this.users.splice(index, 1);
          return {
            success: true,
            data: deleted,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting download.
       * The download will automatically be saved in the database and updated in the store.
       * @param download The download.
       */
      async toggleAdmin(id: string) {
        this.loading++;
        try {
          const toggledAdmin = await json<User>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'toggle-admin',
              id,
            }
          });
          if (toggledAdmin) {
            const index = this.users.findIndex((user: User) => user._id === toggledAdmin._id);
            this.users[index] = toggledAdmin;
            return {
              success: true,
              data: toggledAdmin,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Enables the activated property of a user, therefore authenticates the user.
       * The user will automatically be saved in the database and updated in the store.
       * @param id The ID of the user to authenticate.
       */
      async enableActivated(id: string) {
        this.loading++;
        try {
          const authenticatedUser = await json<User>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'enable-activated',
              id,
            }
          });
          if (authenticatedUser) {
            const index = this.users.findIndex((user: User) => user._id === authenticatedUser._id);
            this.users[index] = authenticatedUser;
            return {
              success: true,
              data: authenticatedUser,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Enables the activated property of a user, therefore authenticates the user.
       * The user will automatically be saved in the database and updated in the store.
       * @param id The ID of the user to authenticate.
       */
      async disable2FA(id: string) {
        this.loading++;
        try {
          const twoFADisabledUser = await json<User>('/api/store/users', {
            method: 'POST',
            json: {
              action: 'disable-2fa',
              id,
            }
          });
          if (twoFADisabledUser) {
            const index = this.users.findIndex((user: User) => user._id === twoFADisabledUser._id);
            this.users[index] = twoFADisabledUser;
            return {
              success: true,
              data: twoFADisabledUser,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  if (preload) {
    store.load();
  }

  // Return the store reference
  return store;
};